import React from 'react'

import Layout from '../components/Layout'
import { LoginForm } from '../components/LoginForm'

const NotFoundPage = () => (
  <Layout>
    <LoginForm />
    <div className='text-gray-200 text-center'>
      <h1 className='text-gray-200 mt-4'>NOT FOUND</h1>
      <p>La página que estás buscando no existe.</p>
    </div>
  </Layout>
)

export default NotFoundPage
